export class MobileNav extends HTMLElement {
  constructor() {
    super()
    this.menuButton = this.querySelector('.mobile-nav-toggle')
    this.menuPanel = this.querySelector('.mobile-nav-panel')
    this.closeButton = this.querySelector('.mobile-nav-close')
    this.isOpen = false

    // Bind methods
    this.toggleMenu = this.toggleMenu.bind(this)
    this.closeMenu = this.closeMenu.bind(this)
    this.handleEscapeKey = this.handleEscapeKey.bind(this)
  }

  connectedCallback() {
    // Set initial ARIA attributes
    this.menuButton.setAttribute('aria-controls', 'mobile-nav-panel')
    this.menuButton.setAttribute('aria-expanded', 'false')
    this.menuPanel.id = 'mobile-nav-panel'

    // Add event listeners
    this.menuButton.addEventListener('click', this.toggleMenu)
    this.closeButton.addEventListener('click', this.closeMenu)
    document.addEventListener('keydown', this.handleEscapeKey)
  }

  disconnectedCallback() {
    this.menuButton.removeEventListener('click', this.toggleMenu)
    this.closeButton.removeEventListener('click', this.closeMenu)
    document.removeEventListener('keydown', this.handleEscapeKey)
  }

  toggleMenu() {
    this.isOpen ? this.closeMenu() : this.openMenu()
  }

  openMenu() {
    this.isOpen = true
    this.menuButton.setAttribute('aria-expanded', 'true')
    this.menuPanel.classList.add('is-open')
    document.body.style.overflow = 'hidden'
    this.closeButton.focus()
  }

  closeMenu() {
    this.isOpen = false
    this.menuButton.setAttribute('aria-expanded', 'false')
    this.menuPanel.classList.remove('is-open')
    document.body.style.overflow = ''
    this.menuButton.focus()
  }

  handleEscapeKey(event) {
    if (event.key === 'Escape' && this.isOpen) {
      this.closeMenu()
    }
  }
}

// customElements.define('mobile-nav', MobileNav)
